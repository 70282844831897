
import { Options, Vue } from "vue-class-component";

@Options({
  created() {
    // this.$refs.usernameInput.focus()
  },
  data() {
    return {
      data: null,
    };
  },
  mounted() {
    if (!this.$route.params.data) {
      this.$router.push({
        name: "home",
      });
    } else {
      this.data = JSON.parse(this.$route.params.data);
      document.title = `Breeze - Date with ${this.data.name}`;
    }
  },
  methods: {
    photoLink(photo: string) {
      return process.env.VUE_APP_STORAGE_IMAGE_PATH + photo;
    },
    goToApp() {
      this.$gtag.event("go-to-app", {
        method: "Google",
        url: this.data.link,
      });
    },
  },
})
export default class Home extends Vue {}
